/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* Media Queries */


/* This was added on Friday April 13, 2018 at 5:13 PM. 
	This is intended to fix the overly large images issue
 */
img {
	max-width: 100%;
    height: auto;
}


/* Smartphone Media Queries */

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

	/* For smartphones, add a padding to the top to compensate for the navbar */
	body {
		padding-top: 100px;
	}
	
	
	/* HEADER */
	.header-background-parallax {
	
		margin: 0 auto;
	
		background-color: orange;
	
		height: 100vh;
		min-height: 100vh; /* To compensate for mobile browser address bar space */
		background: /* top, transparent black, faked with gradient */ 
			linear-gradient(
			  rgba(0, 0, 0, 0.7), 
			  rgba(0, 0, 0, 0.7)
			),
			url('/assets/images/backgrounds/header-background-one-1920.jpg') no-repeat center fixed; 
		
		
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
    }
	
	
	
	/* ABOUT */
	.about-profile-image {
		max-height: 320px;
		max-width: 320px;
	}
	
	
	
	
	
	
	/* WORK */
	.work-background-parallax {
	
		margin: 0 auto;
	
		background-color: orange;
	
		
		min-height: 100vh; /* To compensate for mobile browser address bar space */
		background: /* top, transparent black, faked with gradient */ 
			linear-gradient(
			  rgba(0, 0, 0, 0.7), 
			  rgba(0, 0, 0, 0.7)
			),
			url('/assets/images/backgrounds/header-background-one-1920.jpg') no-repeat center scroll; 
		
		
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
    }
    
    .work-testimonial-item-large {
		width: 100%;
	}
	.work-testimonial-item-small {
		width: 100%;
	}
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) {
/* Styles */

	/* HEADER */
	
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 320px) {
/* Styles */

	/* HEADER */
	
}







/* iPad Media Queries */

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
	
	
	/* HEADER */

	.header-background-parallax {
	
		margin: 0 auto;
	
		background-color: orange;
	
		height: 100vh;
		min-height: 100vh; /* To compensate for mobile browser address bar space */
		background: /* top, transparent black, faked with gradient */ 
			linear-gradient(
			  rgba(0, 0, 0, 0.7), 
			  rgba(0, 0, 0, 0.7)
			),
			url('/assets/images/backgrounds/header-background-one-1920.jpg') no-repeat center fixed; 
		
		
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
    }
	
	.about-profile-image {
		max-height: 320px;
		max-width: 320px;
	}
	
	
	
	/* WORK */
	.work-background-parallax {
	
		margin: 0 auto;
	
		background-color: orange;
	
		
		min-height: 100vh; /* To compensate for mobile browser address bar space */
		background: /* top, transparent black, faked with gradient */ 
			linear-gradient(
			  rgba(0, 0, 0, 0.7), 
			  rgba(0, 0, 0, 0.7)
			),
			url('/assets/images/backgrounds/header-background-one-1920.jpg') no-repeat center scroll; 
		
		
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
    }
    
    .work-testimonial-item-large {
		width: 100%;
	}
	.work-testimonial-item-small {
		width: 100%;
	}
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
/* Styles */

	/* HEADER */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
/* Styles */

	/* HEADER */
}








/* Desktop and Large screen Media Queries */

/* Medium Devices, Desktops and Laptops */
@media only screen and (min-width : 990px) {
/*@media only screen and (min-width : 1100px) {*/
/* Styles */


	/* HEADER */

	.header-background-parallax {
	
		margin: 0 auto;
	
		background-color: orange;
	
		height: 100vh;
		min-height: 100vh; /* To compensate for mobile browser address bar space */
		background: /* top, transparent black, faked with gradient */ 
			linear-gradient(
			  rgba(0, 0, 0, 0.7), 
			  rgba(0, 0, 0, 0.7)
			),
			url('/assets/images/backgrounds/header-background-one-1920.jpg') no-repeat center fixed; 
		
		
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
    }
    
    /* ABOUT */
    .about-profile-image {
		max-height: 320px;
		max-width: 320px;
		padding: 10px;
	}
    
    /* WORK */
	.work-background-parallax {
	
		margin: 0 auto;
	
		background-color: orange;
	
		
		min-height: 100vh; /* To compensate for mobile browser address bar space */
		height: auto;
		background: /* top, transparent black, faked with gradient */ 
			linear-gradient(
			  rgba(0, 0, 0, 0.7), 
			  rgba(0, 0, 0, 0.7)
			),
			url('/assets/images/backgrounds/header-background-one-1920.jpg') no-repeat center fixed; 
		
		
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
    }
    
    .work-testimonial-item-large {
		width: 70%;
	}
	.work-testimonial-item-small {
		width: 30%;
	}

}

/* Large screens ----------- */
@media only screen  and (min-width : 1824px) {
/* Styles */


	/* HEADER */
	
	.header-background-parallax {
	
		margin: 0 auto;
	
		background-color: orange;
	
		height: 100vh;
		min-height: 100vh; /* To compensate for mobile browser address bar space */
		background: /* top, transparent black, faked with gradient */ 
			linear-gradient(
			  rgba(0, 0, 0, 0.7), 
			  rgba(0, 0, 0, 0.7)
			),
			url('/assets/images/backgrounds/header-background-one-1920.jpg') no-repeat center fixed; 
		
		
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
    }
    
    
    
    
    
    /* WORK */
	.work-background-parallax {
	
		margin: 0 auto;
	
		background-color: orange;
	
		
		min-height: 100vh; /* To compensate for mobile browser address bar space */
		background: /* top, transparent black, faked with gradient */ 
			linear-gradient(
			  rgba(0, 0, 0, 0.7), 
			  rgba(0, 0, 0, 0.7)
			),
			url('/assets/images/backgrounds/header-background-one-1920.jpg') no-repeat center fixed; 
		
		
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
    }
    
    .work-testimonial-item-large {
		width: 70%;
	}
	.work-testimonial-item-small {
		width: 30%;
	}
}





/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/*  */








/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/*  */






 

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* GLOBAL */

/* HTML-specific classes */
html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: white;
}


/* Remove underline from anchor tags */
a {
    text-decoration: none !important;
}

/* Custom fonts for these tags */
h1, 
h2,
h3,
h4,
p {
	font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

/* Remove border from anchor links after clicking them */
a:active, a:focus {
  	outline: 0;
  	border: none;
  	-moz-outline-style: none
}

section,
div {
	overflow-x: hidden;
}

.container-fluid {
    height: 100%;
    width: 100%;
    overflow-y: hidden; /* don't show content that exceeds my height */
}

/* Fixes issue where selecting a navbar link will hide some content on page */
.anchor-link {
  	display: block;
  	height: 100px; /*same height as header*/
  	margin-top: -100px; /*same height as header*/
	visibility: hidden;
}






/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* For the down-arrow in the header */





/* This is the little down-arrow that is used to scroll to the second section */
.header-scroll-down {
	position: absolute;
	left: 50%;
	bottom: 30px;
	margin-left: -16px;
	display: block;
	width: 32px;
	height: 32px;
	/*border: 2px solid #FFF;*/
	background-size: 14px auto;
	border-radius: 50%;
	z-index: 2;
	-webkit-animation: bounce 2s infinite 2s;
	animation: bounce 2s infinite 2s;
	-webkit-transition: all .2s ease-in;
	transition: all .2s ease-in;
}

/* 
	This manages the content of the actual a tag
	top and left: centers within 12 x 12px object
	content: draws a "L" shape
	transform: rotates the "L" shape to resemble a down arrow
*/
.header-scroll-down:before {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 6px);
    transform: rotate(-45deg);
    display: block;
    width: 12px;
    height: 12px;
    content: "";
    border: 2px solid white;
    border-width: 0px 0 2px 2px;
}

/* For smartphones */
@media (max-width: 767px) {
	.header-scroll-down {
		bottom: 0px;
		top: 100%;
	}
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
.aboutus-background {
	background-color: white;
}

.about-area-p {
	color: #3e45af; 
	font-size:24px;
}


body {
  	background-color: #000;
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

section {
  height: 100vh;
  width: 100%;
  display: table;
  /*
  border-top: 0.5px solid #999; 
  border-bottom: 0.5px solid #999;
  */
}





























/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* Header */

.header-container {
	position: relative;
	top: 50%;
}

.header-text {
	color: #f8f8f8;
	font-family: "Avenir Next" !important;
	text-shadow: 4px 4px 4px #303030;
}
.header-first-text {
	font-size: 40px;
}
.header-second-text {
	font-size: 28px;
}








/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* About */

.about-container {
	padding: 50px;
}
.about-row {
	padding-top: 70px;
	padding-bottom: 70px;
}
.about-skill-title {
	font-size: 18px;
	font-family: "Open Sans";
	color: #2e2e2e;
	margin-top: 20px;
	margin-bottom: 20px;
}
.about-skill-text {
	font-size: 14px;
	font-family: "Open Sans";
	/*
	color: #6a6a6a;
	*/
}
.about-me-text {
	/* top | right | bottom | left */
	padding: 0px 40px 0px 40px;
	font-family: "Open Sans";
	/*
	color: #7d7d7d;
	*/
}









/* TODO:
These have been deprecated as of 10/19/19 in favor
of a reactjs package: react-skillbar
You may delete these */

.skillbar {
	position:relative;
	display:block;
	margin-bottom:15px;
	width:100%;
	background:#eee;
	height:35px;
	border-radius:3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	-webkit-transition:0.4s linear;
	-moz-transition:0.4s linear;
	-ms-transition:0.4s linear;
	-o-transition:0.4s linear;
	transition:0.4s linear;
	-webkit-transition-property:width, background-color;
	-moz-transition-property:width, background-color;
	-ms-transition-property:width, background-color;
	-o-transition-property:width, background-color;
	transition-property:width, background-color;
}

.skillbar-title {
	position:absolute;
	top:0;
	left:0;
	width:110px;
	font-weight:bold;
	font-size:13px;
	color:#ffffff;
	background:#6adcfa;
	-webkit-border-top-left-radius:3px;
	-webkit-border-bottom-left-radius:4px;
	-moz-border-radius-topleft:3px;
	-moz-border-radius-bottomleft:3px;
	border-top-left-radius:3px;
	border-bottom-left-radius:3px;
}


.skillbar-title span {
	display:block;
	background:rgba(0, 0, 0, 0.1);
	padding:0 20px;
	height:35px;
	line-height:35px;
	/*
	-webkit-border-top-left-radius:3px;
	-webkit-border-bottom-left-radius:3px;
	-moz-border-radius-topleft:3px;
	-moz-border-radius-bottomleft:3px;
	border-top-left-radius:3px;
	border-bottom-left-radius:3px;
	*/
}

.skillbar-bar {
	height:35px;
	width:0px;
	background:#6adcfa;
	/*
	border-radius:3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	*/
}

.skill-bar-percent {
	position:absolute;
	right:10px;
	top:0;
	font-size:11px;
	height:35px;
	line-height:35px;
	color:#ffffff;
	color:rgba(0, 0, 0, 0.4);
}

/* The other options offer too much visual stimulation (as Shakir (Uniqlo) once said...) */
.about-default-skill-title { background: #5926d6; }
.about-default-skill-bar { background: #5318e4; }
/*
	No longer using separate colors for each skill bar

.resume-java-skill-title {background: #2980b9;}
.resume-java-skill-bar {background: #3498db;}

.resume-html-skill-title {background: #2c3e50;}
.resume-html-skill-bar {background: #2c3e50;}

.resume-css-skill-title {background: #46465e;}
.resume-css-skill-bar {background: #5a68a5;}

.resume-javascript-skill-title {background: #333333;}
.resume-javascript-skill-bar {background: #525252;}

.resume-react-skill-title {background: #27ae60;}
.resume-react-skill-bar {background: #2ecc71;}

.resume-php-skill-title {background: #124e8c;}
.resume-php-skill-bar {background: #4288d0;}

.resume-sql-skill-title {background: #234522;}
.resume-sql-skill-bar {background: #1efefe;}
*/















/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* Portfolio */

/* Add some top and bottom padding to each OVERALL row */
.portfolio-row {
	padding: 30px;
}



/* These two are for the actual modal that opens */
.modal-photos-image {
	opacity: 1;
	display: block;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	
	
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-photos-cancel-button {
	position: relative;
	
	margin: 40px;
	
	display: flex;
	align-items: center;
	justify-content: center;
}
























/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* Resume */

.resume-items-container {
	position: relative;
	margin: 0px;
	padding: 0px;
}
.resume-item-outer-container {
	/* This padding to make the items appear to have space in between each other */
	padding: 25px;
	background-color: white;
	border-radius: 5px;
	min-height: 320px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	
	
	/* Change the size of the icon when hovered on and off */
    -webkit-transform: scale(0.95);
    /* Browser Variations: */
     
	-moz-transform: scale(0.95);
	-ms-transform: scale(0.95);
	-o-transform: scale(0.95);
	transform: scale(0.95);
 
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
.resume-item-outer-container:hover {
	-webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    transform: scale(1.01);
} 

.resume-item-company {
	color: #5a5a5a;
}
.resume-item-role {
	color: #808080;
}
.resume-item-employment-timeline {
	color: #5926d6;
}

.resume-technologies-row {
	padding-top: 30px;
	padding-bottom: 30px;
}

.devicon-color {
	color: #696969;
}




/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* Work */
.work-container {
	position: relative;
}

/* Due to differences in sizes for iphone and android images */
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
	.work-mobile-image { max-height: 208px; }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
	.work-mobile-image { max-height: 465px; }
}
/* Medium Devices, Desktops and Laptops */
@media only screen and (min-width : 990px) {
	.work-mobile-image { max-height: 465px; }
}


.work-container p {
	font-family: "Open Sans" !important;
}
.work-text-container {
	height: 100%;
	overflow-y: hidden;
}
.work-client-header {
	color: #2f2f2f;
	font-size: 28px;
	font-weight: bold;
}
.work-role-header {
	color: #909090;
	font-size: 20px;
	
}
.work-client-description {
	color: #787878;
	font-size: 18px;
}
.work-testimonial-container {
	display: flex; 
	flex-flow: column;
	display: inline-block;
}
	
.work-testimonial-item {
	float: left;
	height: auto;
	overflow: hidden;
}
.work-testimonial-name {
	padding-top: 30px;
	padding-bottom: 30px;
}
.work-testimonial-image {
	max-width: 120px;
	max-height: 120px;
}
.work-testimonial-text {
	color: #3c3c3c;
	float: right;
	font-size: 20px;
	padding: 10px;
}

.work-horizontal-rule {
	background-color: #5926d6;
	height: 2px;
}







/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* Contact */


/* Customize the input box */
input.input-box {
	border: none;
	background: transparent;
	min-height: 70px;
	width: 80%;
	left: 10%
}
input.input-box :focus {
	outline: none;    
}

input[type="text"],
input[type="email"] {
	color: #3d3d3d;
	font-family: "Avenir Next" !important;
    font-size:24px;
    text-align: center;
    border: 0;
  	outline: 0;
	  background: transparent;
}

/*
Will now be using a span to mimic this bottom bar 10/20/17
border-bottom: 1px solid rgba(61, 61, 61, 0.2);
*/
	input[type="email"] :focus {
	outline: none;    
}


/*  
	The " + " is known as an adjacent sibling detector.
	It gathers the next element with this className
	and does whatever the class is supposed to do
	When the textarea or input with these classNames 
	are focused, add the underline class 
*/
.textarea-box:focus + .underline,
.input-box:focus + .underline {
  	transform: scale(1);
}

.underline {
	background-color: #5926d6;
	display: inline-block;
	height: 2px;
	/* 20% from the left ensures there will be another 20% on the right */
	width: 60%;
	left: 20%;
	
	
	-webkit-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

/* Customize the textarea */
textarea {
	background: transparent;
	resize: none;
	border: 0 none;
	text-align: center;
	width: 80%;
	left: 10%;
	height: 100%;
	font-size: 24px;
	outline: none;
}
	
/*
Will now be using a span to mimic this bottom bar 10/20/17
border-bottom: 1px solid rgba(61, 61, 61, 0.2);
*/
textarea:focus {
	outline: none;    
}




/* Change properties of the input box placeholder */
/* do not group these rules */
*::-webkit-input-placeholder {
    color: #828183;
    font-family: "Avenir Next" !important;
}
*:-moz-placeholder {
    /* FF 4-18 */
    color: #828183;
    font-family: "Avenir Next" !important;
}
*::-moz-placeholder {
    /* FF 19+ */
    color: #828183;
    font-family: "Avenir Next" !important;
}
*:-ms-input-placeholder {
    /* IE 10+ */
    color: #828183;
    font-family: "Avenir Next" !important;
}

input:required {
    box-shadow:none;
}
input:invalid {
    box-shadow: none;
}




















/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* Backgrounds */


/* No longer in use. Going with all-white page */

.header-background {
	background-color: #c1b3e2; 
}
.about-background {
	background-color: #f7f6f8; 
}
.resume-background {
	background-color: #f7f6f8; 
}

.work-background {
	background-color: #e3e0e5; 
}
.contact-background {
	background-color: #f0eded; 
}

.svg-container-section {
	width: 100%;
	max-height: 100px !important;
	height: 100px;
	overflow-y: hidden;
	padding: 0px;
	margin: 0px;
}
.bottom-pointed-section {
	/* Same as the next section color: (e.g. ContactArea) */
	background-color: #f0eded;
}
.bottom-trapezoid-section {
	/* Same as the current section color: (e.g. ResumeArea) */
	background-color: transparent;
}
svg {
	/* 	
		Remove that annoying bottom spacing. 
		For some reason svg and img elements act like text elements
		and provide a spacing by default */
	display: block;
}
.svg-triangle {
	/* Same as the next section color: (e.g. ContactArea) */
	background-color: #f0eded;
	/* The actual color of the svg-triangle */
	fill: #f5f5f5;
	width: 100%;
	max-height: 100px !important;
	height: 100px;
}


.svg-trapezoid {
	/* Same as the current section color: (e.g. ResumeArea) */
	background-color: transparent;
	/* The actual color of the svg-trapezoid */
	fill: #f5f5f5;
	width: 100%;
	max-height: 100px !important;
	height: 100px;
	padding: 0px;
	margin: 0px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
	.svg-triangle, .svg-trapezoid { height: 80px; }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
	.svg-triangle, .svg-trapezoid { height: 90px; }
}
/* Medium Devices, Desktops and Laptops */
@media only screen and (min-width : 990px) {
	.svg-triangle, .svg-trapezoid { height: 100px; }
}
/** FOR THE DEMO **/









/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* Section title headers */
.section-title-header {
	/*
	color: rgba(89, 75, 58, 1.0);
	*/
	color: #404040;
	font-family: "Open Sans" !important;
    font-size: 48px;
	font-weight: bold;
}
.section-title-header-white {
	color: rgba(234, 234, 234, 1.0);
	font-family: "Open Sans" !important;
    font-size: 48px;
	font-weight: bold;
}






/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* Animations */



.animated-element-container {
	z-index: 10; /* to position it in front of the other content */
  	overflow: hidden; /* to prevent scrollbar appearing */
}

.animated-element {
	opacity: 0;
  	position: relative;
}

/* Fade */

.animated-element.fade {
	opacity: 0;
	transition: all 1.4s linear;
	-o-transition: all 1.4s linear;
	-moz-transition: all 1.4s linear;
	-webkit-transition: all 1.4s linear;
}
.animated-element.fade.in-view {
	opacity: 1;
}

/* Slide left */

.animated-element.slide-left {
	opacity: 0;
	-moz-transition: all 500ms linear;
	-webkit-transition: all 500ms linear;
	-o-transition: all 500ms linear;
	transition: all 500ms linear;
	-moz-transform: translate3d(-100px, 0px, 0px);
	-webkit-transform: translate3d(-100px, 0px, 0px);
	-o-transform: translate(-100px, 0px);
	-ms-transform: translate(-100px, 0px);
	transform: translate3d(-100px, 0px, 0px);
}

.animated-element.slide-left.in-view {
	opacity: 1;
	-moz-transform: translate3d(0px, 0px, 0px);
	-webkit-transform: translate3d(0px, 0px, 0px);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate3d(0px, 0px, 0px);
}

/* Slide up */

.animated-element.slide-up {
	opacity: 0;
	-moz-transition: all 500ms linear;
	-webkit-transition: all 500ms linear;
	-o-transition: all 500ms linear;
	transition: all 500ms linear;
	/* 3D - x , y , z */
	-moz-transform: translate3d(0px, 100px, 0px);
	-webkit-transform: translate3d(0px, 100px, 0px);

	/* This is just 2D - x, y */
	-o-transform: translate(0px, 100px);
	-ms-transform: translate(0px, 100px);

	transform: translate3d(0px, 100px, 0px);
}

.animated-element.slide-up.in-view {
	opacity: 1;
	-moz-transform: translate3d(0px, 0px, 0px);
	-webkit-transform: translate3d(0px, 0px, 0px);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate3d(0px, 0px, 0px);
}


/* Slide right */

.animated-element.slide-right {
	opacity: 0;
	-moz-transition: all 500ms linear;
	-webkit-transition: all 500ms linear;
	-o-transition: all 500ms linear;
	transition: all 500ms linear;
	/* 3D - x , y , z */
	-moz-transform: translate3d(100px, 0px, 0px);
	-webkit-transform: translate3d(100px, 0px, 0px);

	/* This is just 2D - x, y */
	-o-transform: translate(100px, 0px);
	-ms-transform: translate(100px, 0px);

	transform: translate3d(100px, 0px, 0px);
}

.animated-element.slide-right.in-view {
	opacity: 1;
	-moz-transform: translate3d(0px, 0px, 0px);
	-webkit-transform: translate3d(0px, 0px, 0px);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate3d(0px, 0px, 0px);
}














/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* Global CSS rules such as padding, margins, colors, etc. */





/* btn */

a.bttn {
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}
.bttn {
	height: 60px;
	width: 200px;
	font-size: 20px;
	background: transparent;
	color: #999;
	
	
    
    -webkit-transition: all 0.5s ease;
     -moz-transition: all 0.5s ease;
     -ms-transition: all 0.5s ease;
     -o-transition: all 0.5s ease;
     transition: all 0.5s ease;
	
	text-align: center; 
    line-height: 30px;
    
	padding-left: 25px;
	padding-right: 25px;
	
}

a.bttn:hover,
.bttn:hover {
	width: 240px;
	background: #5926d6;
	color: white;
	cursor: pointer;
}

a.bttn-rounded,
.bttn-rounded {
	border-radius: 30px;
}

a.bttn-bordered,
.bttn-bordered {
	border: 3px solid #5926d6;
}


/* Inverted button */
a.bttn-inverted,
.bttn-inverted {
	height: 40px;
	background: #5926d6;
	color: white;
	
	transition: color 0.2s linear; /* vendorless fallback */
    -o-transition: color 0.2s linear; /* opera */
    -ms-transition: color 0.2s linear; /* IE 10 */
    -moz-transition: color 0.2s linear; /* Firefox */
    -webkit-transition: color 0.2s linear; /*safari and chrome */
	
	text-align: center; 
    line-height: 20px;
    
	padding-left: 25px;
	padding-right: 25px;
}

a.bttn-inverted-bordered,
.bttn-inverted-bordered {
	border: 3px solid white;
}

.box-shadow {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
}

.apple-app-store-bttn,
.google-play-store-bttn {
	min-height: 40px;
	/*
	min-width: 80px;
	max-width: 190px;
	*/
	/*
	font-size: 20px;
	line-height: 20px;
    */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
    padding-top: 10px;
    padding-bottom: 10px;
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
	.apple-app-store-bttn,
	.google-play-store-bttn { width: 80px; }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
	.apple-app-store-bttn,
	.google-play-store-bttn { width: 130px; }
}
/* Medium Devices, Desktops and Laptops */
@media only screen and (min-width : 990px) {
	.apple-app-store-bttn,
	.google-play-store-bttn { width: 190px; }
}



/* ----- TEST COLUMNS ----- */
/* Used to determine size and exact divisions between columns or divs */
.test-col-red { background-color: red; }
.test-col-orange { background-color: orange; }
.test-col-yellow { background-color: yellow; }
.test-col-green { background-color: green; }
.test-col-blue { background-color: blue; }
.test-col-purple { background-color: purple; }
.test-col-brown { background-color: brown; }


/* ----- FONT SIZES ----- */

.global-font-size-12px {
	font-size: 12px;
}

.global-font-size-16px {
	font-size: 16px;
}

.global-font-size-18px {
	font-size: 18px;
}

.global-font-size-20px {
	font-size: 20px;
}

.global-font-size-24px {
	font-size: 24px;
}


/* ----- MARGINS & PADDING ----- */


/* top | right | bottom | left */
.global-margin-top-20px {
	margin-top: 20px;
}

.global-margin-top-40px {
	margin-top: 40px;
}

.global-margin-top-60px {
	margin-top: 60px;
}
.global-margin-top-120px {
	margin-top: 120px;
}

.global-margin-bottom-20px {
	margin-bottom: 20px;
}

.global-margin-bottom-40px {
	margin-bottom: 40px;
}

.global-margin-left-zero {
	margin-left: 0px;
}

.global-margin-right-zero {
	margin-right: 0px;
}

.global-margin-top-zero {
	margin-top: 0px;
}

.global-margin-topbottom-40px {
	margin: 40px 0px 40px 0px;
}



.global-padding-20px {
	padding: 20px;
}
/* Useful for box shadows. 30px seems to be a good amount */
.global-padding-30px {
	padding: 30px;
}
.global-padding-40px {
	padding: 40px;
}
.global-padding-top-40px {
	padding: 40px 0px 0px 0px;
}
.global-padding-top-bottom-20px {
	padding: 20px 0px 20px 0px;
}
.global-padding-top-bottom-40px {
	padding: 40px 0px 40px 0px;
}
.global-padding-top-bottom-20px {
	padding: 60px 0px 60px 0px;
}
.global-padding-leftright-40px {
	padding-left: 40px;
	padding-right: 40px;
}


/* This causes issues on devices */
.center {
	display: flex;
    align-items: center;
    justify-content: center;
}

.horizontal-center {
	display: table;
  	margin: 0 auto;
}
.vertical-center {
	display: table-cell;
    vertical-align: middle;
}

/* 
	None of these work at the moment
	Attempted on: WorkArea
.outer-vertical-align-wrapper {
	display: table;
  	width: 100%;
  	height: 100%;
}
.inner-vertical-align-wrapper {
	display: table-cell;
  	text-align: center;
  	vertical-align: middle;
}
.flexbox {
	display:flex;
	justify-content:center;
	align-items: center;
	flex-flow: column;
} 
*/


/* ----- DIMENSIONS ----- */
.min-height-200px {
	min-height: 320px;
}

.width-25percent {
	float: left;
	min-width: 25%;
	max-width: 25%;
	width: 25%;
}

.height-300px {
	min-height: 300px;
	max-height: 300px;
}	



/* ----- COLORS ------ */

.nice-gray-font-color {
	color: #727575;
}


.global-cool-white-background {
	background-color: #f5f5f5;
}
.global-cool-white-textcolor {
	color: #f5f5f5;
}
/* Some kind of mint green */
.global-theme-color {
	color: #5926d6;
}

.global-theme-background-color {
	background-color: #5926d6;
	background: #5926d6;
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */