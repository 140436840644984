/* TODO:
This needs to be refactored and cleaned up.
It has redundant and otherwise, outdated 
css rules.
It should only contain rules relevant to each project */

section {
	/* min-height: 100vh; */
	min-width: 100vw;
	/* top | right | bottom | left */
	padding: 40px 0px 40px 0px;
}

p {
	font-family: "Montserrat";
	/*
	font-family: "Open Sans";
	font-family: "Lato" ! important;
	*/
	color: #626262;
}







/* Prevent the background
from being able to scroll while mile stone modal is open */
.ReactModal__Body--open {
	overflow-y: hidden;
}









/* TODO
This can be a global 
Dismiss X symbol */

.dismiss-x-symbol {
	position: absolute;
	right: 		40px;
	top: 		40px;
	width: 		32px;
	height: 	32px;
	opacity: 	0.3;
  }
  .dismiss-x-symbol:hover {
	opacity: 1;
  }
  .dismiss-x-symbol:before, .dismiss-x-symbol:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 50px;
	width: 2px;
	background-color: #333;
  }
  .dismiss-x-symbol:before {
	transform: rotate(45deg);
  }
  .dismiss-x-symbol:after {
	transform: rotate(-45deg);
  }
  
  








.portfolio-header-section {
	/* top | right | bottom | left */
	padding: 50px 0px 50px 0px;
	background-color: #fbfbfb;
}
.images-section {
	background-color: #fbfbfb;
}






.portfolio-progression-section {
	background-color: #fbfbfb;
}
.portfolio-progression-text {
	font-size: 24px;
}




.portfolio-milestone-section {
	background-color: #fbfbfb;
	padding: 40px 0 40px 0;
}

.portfolio-milestone-container {
	height: 180px;
	background-color: #fbfbfb;
	cursor: pointer;

	border-radius: 12px;
	border: 4px solid #5926d6;

	position: relative;
	overflow-y: hidden;

	/* For making everything centered */
	display: flex;
	justify-content: center;
	align-items: center;
}



.portfolio-milestone-text {
	margin: 0;
	font-size: 24px;
	padding: 0 12px 0 12px;
	color: #5926d6;
}
.portfolio-milestone-thumbnail-image {
	overflow: hidden;
}
.portfolio-milestone-thumbnail-image-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	
	transition: all 0.7s ease; /* vendorless fallback */
	-o-transition: all 0.7s ease; /* opera */
	-ms-transition: all 0.7s ease; /* IE 10 */
	-moz-transition: all 0.7s ease; /* Firefox */
	-webkit-transition: all 0.7s ease; /*safari and chrome */
}




/* Make the overlay visible with purple color when the image container is hovered on */
.portfolio-milestone-container:hover .portfolio-milestone-thumbnail-image-overlay {
	color: #5926d6;
	background: #5926d6;
	background-color: #5926d6;
	cursor: pointer;
	opacity: 0.7;
}


















.portfolio-milestone-modal-base {

	top                   : 50%;
    left                  : 50%;
    right                 : auto;
	bottom                : auto;
    margin-right           : -50%;
    transform             : translate(-50%, -50%);
    background-color       : red;
}


.portfolio-milestone-modal-overlay {
	position: fixed;
    top: 0;
  	left: 0;
    right: 0;
    bottom: 0;
	background-color: rgba(0, 0, 0, 0.84);

	/* This is important for making the modal background
	cover the 'fixed' navbar */
	z-index: 10000 !important;
}

.portfolio-milestone-modal-content {

	position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    overflow: auto;
      /* WebkitOverflowScrolling: 'touch', */
    border-radius: 4px;
    outline: none;
	padding: 20px;
	
	
	/* This is important for making the actual modal
	cover the background. The z-index must be
	greater than that of portfolio-milestone-modal-overlay */
	z-index: 10001 !important;
}

.portfolio-modal-milestone-container {
	background-color: #fbfbfb;
}

.portfolio-modal-milestone-title-text {
	font-size: 36px;
	font-weight: 1500;
	padding: 30px 0 30px 0;
}

.portfolio-modal-milestone-description-text {
	font-size: 20px;
	padding: 20px 0 20px 0;
}

.porfolio-milestone-modal-video {
	height: 100%;
	width: 100%;
}


















/* 
Prevent the images/gifs at the top of a portfolio 
detail screen from scrolling vertically.
*/
.portfolio-image-video-container {
	overflow-y: hidden;
	
}

.portfolio-image-mobile {
	max-width: 280px;
	max-height: 550px;
}


/* Responsive video */

video {
	width: 100%;
	height: auto;
}

.portfolio-video-wrapper {
	/* max-height: 600px; */
	overflow-y: hidden;
	/* border: 2px solid red; */
}

.video-responsive {
	position: relative;
	/* This is for dimensons: 16:9 */
	padding-bottom: 56.25%; 
	padding-top: 25px;
	height: 0;
}
.video-responsive iframe {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.modal-milestone-video-footer-text {
	text-align: center;
}





.portfolio-languages-used-header {
	font-size: 24px;
}

.portfolio-languages-used-text {
	font-size: 18px;
	color: #5926d6;
}

.portfolio-technologies-used-header {
	font-size: 20px;
}

.portfolio-technologies-used-text {
	font-size: 18px;
	color: #5926d6;
}





.portfolio-about-text {
	font-size: 20px;
}

.information-section {
	background-color: #f1f1f1;
	/* top | right | bottom | left */
	padding: 50px 0px 50px 0px;
}

/* This is used in portfoliodetailcomponent.js */
.paragraph-link {
	color: #5926d6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Montserrat";
	color: #454545;
}

















.center {
	display: flex;
    align-items: center;
    justify-content: center;
}

.horizontal-center {
	display: table;
  	margin: 0 auto;
}


















.box-shadow {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
}

.apple-app-store-bttn,
.google-play-store-bttn {
	min-height: 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
    padding-top: 10px;
    padding-bottom: 10px;
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
	.apple-app-store-bttn,
	.google-play-store-bttn { width: 80px; }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
	.apple-app-store-bttn,
	.google-play-store-bttn { width: 130px; }
}
/* Medium Devices, Desktops and Laptops */
@media only screen and (min-width : 990px) {
	.apple-app-store-bttn,
	.google-play-store-bttn { width: 190px; }
}