.footer-row-top-margin {
	margin-top: 10px; 
}

.footer-padding-50px {
	padding: 50px;
}

.footer-background-color {
	background-color: #2e343d;
}

.footer-text-color {
	color: #abaaaa;
}


/* Change color of footer ul list item when hovered on. PART ONE */
.fa {
  	color: #abaaaa;
	transition: color 0.2s linear; /* vendorless fallback */
    -o-transition: color 0.2s linear; /* opera */
    -ms-transition: color 0.2s linear; /* IE 10 */
    -moz-transition: color 0.2s linear; /* Firefox */
    -webkit-transition: color 0.2s linear; /*safari and chrome */
}
/* PART TWO */
.fa:hover {
	/* Some kind of mint color */
	color: #5926d6;
    cursor: pointer;
}




/* Change the size of the icon when hovered on and off */

 .social {
     -webkit-transform: scale(0.8);
     /* Browser Variations: */
     
     -moz-transform: scale(0.8);
     -ms-transform: scale(0.8);
     -o-transform: scale(0.8);
     transform: scale(0.8);
     
     -webkit-transition: all 1.0s ease;
     -moz-transition: all 1.0s ease;
     -ms-transition: all 1.0s ease;
     -o-transition: all 1.0s ease;
     transition: all 1.0s ease;
}
 
.social:hover {
     -webkit-transform: scale(1.1);
     -moz-transform: scale(1.1);
     -ms-transform: scale(1.1);
     -o-transform: scale(1.1);
     transform: scale(1.1);
 }