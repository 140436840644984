
/* Add some top and bottom padding for each column in every row (To properly show box-shadow */
.animatable-card-column {
  	padding-top: 30px;
  	padding-bottom: 30px;

}

/* Finally customize the actual row-item */
.animatable-card-row-item {
	background-color: white;
  	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 	/* text-align: center; */
	border-radius: 5px;
}

.animatable-card-link-container {
	/* max-height: 330px; */
	/* max-width: 450px; */
}

	
/* The following classes add a mouseover effect to allow viewer to see modal about project */
.animatable-card-image-container {
	position: relative;
	overflow-y: hidden;
}

.animatable-card-thumbnail-image {
	display: block;
	height: 100%;
	width: 100%;
	opacity: 1;
	transition: all .5s ease;
	backface-visibility: hidden;
	
	transition: all 0.7s ease; /* vendorless fallback */
	-o-transition: all 0.7s ease; /* opera */
	-ms-transition: all 0.7s ease; /* IE 10 */
	-moz-transition: all 0.7s ease; /* Firefox */
	-webkit-transition: all 0.7s ease; /*safari and chrome */
}

.animatable-card-image-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	
	transition: all 0.7s ease; /* vendorless fallback */
	-o-transition: all 0.7s ease; /* opera */
	-ms-transition: all 0.7s ease; /* IE 10 */
	-moz-transition: all 0.7s ease; /* Firefox */
	-webkit-transition: all 0.7s ease; /*safari and chrome */
}




/* Make the overlay visible with purple color when the image container is hovered on */
.animatable-card-image-container:hover .animatable-card-image-overlay {
	color: #5926d6;
	background: #5926d6;
	background-color: #5926d6;
	cursor: pointer;
	opacity: 0.7;
}

/* Rotate and zoom the thumbnail image when the image container is hovered on */
.animatable-card-image-container:hover .animatable-card-thumbnail-image {
    transform: rotate(-12deg) scale(1.2); /* vendorless fallback */
    -o-transform: rotate(-12deg) scale(1.2); /* opera */
    -ms-transform: rotate(-12deg) scale(1.2); /* IE 9 */
    -moz-transition: rotate(-12deg) scale(1.2); /* Firefox */
    -webkit-transform: rotate(-12deg) scale(1.2); /* Chrome, Safari, Opera */
}

.animatable-card-image-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);	
}

.animatable-card-text-container {
	padding-top: 30px;
	min-height: 100px;
}
.animatable-card-item-text {
	font-size: 24px;
	font-weight: bold;
	color: #808080;
	
}