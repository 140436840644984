/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

/* Navbar specific classes */

/*This is how you change the height of the navbar*/
.navbar {
    min-height: 100px;
}

.navbar-brand {
    /* padding: 0 15px; */
    height: 100px;
    line-height: 100px;
}

/* To center the buttons in the navbar. See arithmetic for finding height */
.navbar-toggle {
  /* (80px - button height 34px) / 2 = 23px */
  /* (100px - button height 34px) / 2 = 33px */
  /* (120px - line-height of 34px) / 2 = 43px */
  /* (140px - line-height of 34px) / 2 = 53px */
  margin-top: 33px;
  padding: 9px 10px !important;
}







/* For the navbar scrolling effect */
.navbar {
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  -ms-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  
  background-color: transparent;
  background: transparent;
  border-color: transparent;
}

/* When the navbar is scrolled past determined height, add this class */
.navbar.navbar-custom.scrolled {
  background: #ffffff;
 
 /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; */
 /*
 
     The horizontal offset (required) of the shadow, 
     positive means the shadow will be on the right of the box, 
     a negative offset will put the shadow on the left of the box.
  
  The vertical offset (required) of the shadow, 
  a negative one means the box-shadow will be above the box, 
  a positive one means the shadow will be below the box.	
  
  The blur radius (required), if set to 0 the shadow will be sharp, 
  the higher the number, the more blurred it will be, 
  and the further out the shadow will extend. 
  For instance a shadow with 5px of horizontal offset 
  that also has a 5px blur radius will be 10px of total shadow.

  The spread radius (optional), positive values increase the size of the shadow, 
  negative values decrease the size. Default is 0 (the shadow is same size as blur).

  Color (required) - takes any color value, like hex, named, rgba or hsla. 
  If the color value is omitted, box shadows are drawn in the foreground color (text color). 
  But be aware, older WebKit browsers (pre Chrome 20 and Safari 6) ignore the rule when color is omitted.
 
 */
     -webkit-box-shadow:	0px 8px 12px -4px #d1d1d1;
       -moz-box-shadow: 0px 8px 12px -4px #d1d1d1;
            box-shadow: 0px 8px 12px -4px #d1d1d1;
}

.navbar-scrolled,
header {
  background: red;
}


/* Dropdown */
.dropdown-menu {
  background-color: white;
  min-width: 200px;
}

.navlink-dropdown-item {
  color: #999;
  /* TODO: This looks like a mistake. It should either be just margin: 
  (without the left)
  Or specify only one value */
  margin-left: 10px 4px 10px 4px;
  font-size: 16px;
  width: 100%;
  height: 100%;
}

a.navlink-dropdown-item:hover {
  color: #5926d6;
}







.navbar.navbar-custom {
    margin-bottom: 19px;
    background-color: transparent;
     background: transparent;
     border-color: transparent;
}


/*Other navbar menu font items*/
/* Change color of navbar ul list item when hovered on. PART ONE */
.navbar-menu-item-link {
    /* These are the initial values */
    font-size: 20px;
    outline: none;
    color: #b4bdcb;
    padding-left: 12px;
    padding-right: 12px;
    margin: auto;
    font-family: "Montserrat" !important;
    transition: color 0.2s linear; /* vendorless fallback */
    -o-transition: color 0.2s linear; /* opera */
    -ms-transition: color 0.2s linear; /* IE 10 */
    -moz-transition: color 0.2s linear; /* Firefox */
    -webkit-transition: color 0.2s linear; /*safari and chrome */
}
/* PART TWO */
.navbar-menu-item-link:hover {
  color: #5926d6;
  cursor: pointer;
}



/* Change the color of navbar list item for ScrollSpy (When scrolled to specified section) */
.navbar.navbar-custom .navbar-nav > li.active > a, 
.navbar.navbar-custom .navbar-nav > li.active > a:focus, 
.navbar.navbar-custom .navbar-nav > li.active > a:hover {
  /*
  color: #9467da !important;
  */
  background: #5926d6;
  background-color: transparent;
  box-shadow: none;
  border-bottom: 3px solid #5926d6;
}






/* Change color of the navbar ul list item when tapped on */
/*For when a link in the navbar has been pressed and is in an active state*/
.navbarItemActive ul > a {
  border-radius: 20px; 
  color: #1382e5;
}

/* Change color of navbar brand when hovered on. PART ONE */
.navbar.navbar-custom .navbar-brand {
  padding: 14x15px;
  font-size: 28px;
  color: white;
  font-family: "Montserrat" !important;
  transition: color 0.2s linear; /* vendorless fallback */
  -o-transition: color 0.2s linear; /* opera */
  -ms-transition: color 0.2s linear; /* IE 10 */
  -moz-transition: color 0.2s linear; /* Firefox */
  -webkit-transition: color 0.2s linear; /*safari and chrome */
}
/* PART TWO */
.navbar.navbar-custom .navbar-brand:hover {
  color: #5926d6;
  cursor: pointer;
}

/* Change color of navbar-brand when scrolled past home */
.navbar-menu-item-link.scrolled,
.navbar.navbar-custom .navbar-brand.scrolled {
  color: #5926d6;
}  

/* Change color of the navbar collapsed toggle button */
.navbar.navbar-custom .navbar-toggle {
  border-color: transparent;
  background: #5926d6;
}

/* Change color of the actual spans (the straight lines used to open a collapsed navbar on small devices) */
.navbar.navbar-custom .navbar-toggle .icon-bar {
  background: white;
    border-color: transparent;
}



/* For larger devices (Tablets and larger) */
@media (min-width: 768px) {
    .navbar-menu-item-link {
      /* (80px - line-height of 27px) / 2 = 26.5px */
      /* (100px - line-height of 27px) / 2 = 36.5px */
      /* (120px - line-height of 27px) / 2 = 46.5px */
      /* (140px - line-height of 27px) / 2 = 56.5px */
      /* padding-top: 36.5px; */
      /* padding-bottom: 36.5px; */
      /* line-height: 27px; */


      /* The above has been proven not work */

      /* margin: auto;
      padding: 10px; */
    }
}



/* For smartphones */
/* For changing the colors of the navbar, navbar-brand and links when navbar is in collapse mode (small devices) */
@media (max-width: 767px) {
    
  .navbar.navbar-custom .navbar-brand,
  .navbar-menu-item-link {
      color: #5926d6;
  }
    
    /* For adding padding for menu items when navbar is in collapse mode */
    .navbar-nav > li {
      padding-left: 20px;
      padding-right: 20px;
  }
}